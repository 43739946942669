<template>
  <div>
    <v-date-picker 
      id="calendar"
      v-model="picker"
      :landscape="landscape"
      :reactive="reactive"
      :full-width="fullWidth"
      :show-current="showCurrent"
      :type="month ? 'month' : 'date'"
      :multiple="multiple"
      :readonly="readonly"
      :disabled="disabled"
      :loading="loading"
      :events="eventDates"
      locale="de-de"
    >
      <v-content id="content">
        <a target="_blank" v-for="(item, index) in filteredEvents" v-bind:key="index" :href="item.link" :title="item.title">{{ item.title }}</a>
     </v-content>
    </v-date-picker>
    
  </div>
</template>
<style lang="less" scoped>
  #content {
    max-width:100%;
    a {
      display:block;
      white-space:normal;
      padding-bottom:4px;
      border-bottom:1px solid #eee;
      &:last-child {
        border-bottom:0;
        padding-bottom:0;
      }
    }
  }
  
</style>
<script>
  import axios from 'axios'  
  export default {
    props: ['jsondata'],
    data () {
      return {
        picker: new Date().toISOString().substr(0, 10),
        landscape: false,
        loading: true,
        reactive: false,
        fullWidth: true,
        showCurrent: false,
        month: false,
        multiple: false,
        readonly: false,
        disabled: false,
        enableEvents: true,
        axiosData: null,
        axiosLoading: true,
        axiosErrored: false,
        events:[],
        eventDates:[],
      }
    },

    computed: {
      jsonData() {
          return this.axiosData;
      },
      filteredEvents() {
          let filteresults = [];
          for (const entry of this.events) {
            if (entry.date == this.picker) {
                filteresults.push(entry)
            }
          }
          return filteresults;
      }
    },
    async mounted() {
      try{
        let response = await axios.get(this.jsondata)
        this.axiosData = response
        this.events = response.data.events
        for (const entry of this.events) {
          this.eventDates.push(entry.date)
        }
      }catch(err){
        console.log(err)
      }
    },
  }
</script>
