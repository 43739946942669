import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import vuetify from './plugins/vuetify';
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

App.store = store
App.vuetify = vuetify

Vue.customElement('vuidget-calendar', App)