import Vue from 'vue';
import Vuetify, {
  VDatePicker,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  components: {
    VDatePicker,
  },
  directives: {
    Ripple,
  },
})


export default new Vuetify({
theme: {
	themes: {
		light: {
			primary: '#00c0ff',
			secondary: '#b0bec5',
			accent: '#8c9eff',
			error: '#b71c1c',
			success: '#8ec200',
			},
		},
	},
});