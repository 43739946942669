<template>
  <v-app>
      <vuidgetCalendar :jsondata="jsonUrl" />
  </v-app>
</template>

<script>
import vuidgetCalendar from './components/vuidgetCalendar';


export default {
  name: 'App',
  data () {
      return {
        jsonUrl: null
      }
  },
  components: {
    vuidgetCalendar,
  },
  created: function() {
      this.jsonUrl = document.getElementById('cc_vuidget_calendar').getAttribute('data-json-url');
      console.log(this.jsonUrl);
  }
};
</script>

<style> 
    #calendar {
      display:table;
      margin:auto;
      width:100%;
    }
</style>